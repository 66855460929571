import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import {
  renderPageComponents,
  getStatusAlertComponent,
  releaseDateFormat,
} from '../util/contentful-render-utils';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../themes/main-colors';
import BreadCrumb from '../components/pge-breadcrumb';
import Container from '@material-ui/core/Container';
import './css/contentful-pages.css';
import { LanguageContext } from '../providers/LanguageProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      backgroundColor: colors.white,
      width: '100%',
    },
    root: {
      padding: theme.spacing(5, 7.5, 0, 7.5),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
    },
    heading: {
      fontFamily: 'Forma-DJR-Display',
      fontSize: theme.spacing(4.5),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(40),
      padding: theme.spacing(0, 0, 2.5, 0),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
        padding: theme.spacing(0, 0, 1.25, 0),
        marginBottom: theme.spacing(1.25),
      },
    },
    description: {
      fontFamily: 'Forma-DJR-Display',
      fontSize: theme.spacing(3),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(28),
      marginBottom: theme.spacing(2.5),
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(24),
      },
    },
    date: {
      margin: 0,
      fontSize: theme.spacing(2),
      fontFamily: 'Forma-DJR-Display',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(1.75),
      },
    },
    image: {
      width: '100%',
      borderRadius: 5,
      height: 275,
      marginBottom: theme.spacing(2.5),
      objectFit: 'cover',
    },
  }),
);

export const NewsTemplate = ({ pageContext, data }: any) => {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const page = pageContext[language || 'en'];

  // ** Start temporary rollback of localization for public pages **
  const newsReleasePageData = data.allContentfulPageNewsRelease?.nodes[0];
  // const newsReleasePageData =
  //   data.allContentfulPageNewsRelease?.nodes.find(
  //     (node: any) => node.node_locale === language,
  //   ) || data.allContentfulPageNewsRelease?.nodes[0];
  // ** End temporary rollback of localization for public pages **

  const newsImageURL = newsReleasePageData?.releaseImage?.file?.url;

  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>
          {newsReleasePageData?.browserTitle ||
            newsReleasePageData?.releaseTitle}
        </title>
        {newsReleasePageData?.metaDescriptionSeo && (
          <meta
            name="description"
            content={newsReleasePageData.metaDescriptionSeo}
          />
        )}
        {newsReleasePageData?.excludeFromGoogleSearch && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>

      <Container className="component-container">
        <div style={{ width: '100%' }}>
          <BreadCrumb path={page?.breadcrumb} />
          {getStatusAlertComponent(newsReleasePageData?.statusAlert)}
          {newsReleasePageData?.releaseTitle && (
            <h1 className={classes.heading}>
              {newsReleasePageData?.releaseTitle}
            </h1>
          )}
          {newsReleasePageData?.releaseSubtitle && (
            <div className={classes.description}>
              {newsReleasePageData?.releaseSubtitle}
            </div>
          )}
          {newsImageURL && (
            <img
              className={classes.image}
              src={newsImageURL}
              height="275"
              width="1200"
            />
          )}
          {newsReleasePageData?.releaseDate && (
            <h4 className={classes.date}>
              {releaseDateFormat(newsReleasePageData?.releaseDate)}
            </h4>
          )}
          {renderPageComponents({
            entriesData: newsReleasePageData?.releaseContentEntries || null,
            pageTemplate: newsReleasePageData?.pageTemplate || null,
          })}
          {renderPageComponents({
            entriesData: newsReleasePageData?.boilerplate || null,
            pageTemplate: newsReleasePageData?.pageTemplate || null,
          })}
          {renderPageComponents({
            entriesData: newsReleasePageData?.mediaContact || null,
            pageTemplate: newsReleasePageData?.pageTemplate || null,
          })}
        </div>
      </Container>
    </>
  );
};

export default NewsTemplate;

export const query = graphql`
  query PageNewsRelease($slug: String!) {
    allContentfulPageNewsRelease(filter: { slug: { eq: $slug } }) {
      nodes {
        node_locale
        contentful_id
        entryName
        shortMenuTitle
        slug
        releaseTitle
        releaseSubtitle
        releaseDate
        releaseImage {
          file {
            contentType
            fileName
            url
          }
        }
        pageTemplate
        browserTitle
        metaDescriptionSeo
        excludeFromGoogleSearch
        excludeFromXmlSiteMap
        statusAlert {
          ...ModuleStatusAlert
        }
        boilerplate {
          ...ModuleNote
          ...ModuleContentBody
        }
        releaseContentEntries {
          ...ElementJumpLinks
          ...ModuleContentBody
          ...ModuleNote
          ...ModuleStatusAlert
          ...ModuleCallOut
          ...ModuleContact
          ...ModuleAccordion
          ...ModuleContentList
          ...ModuleDocumentList
          ...GallerySlider
          ...ModulePromoGroup
          ...ModuleRelated
          ...ModuleTabSet
          ...ModuleTOC
          ...ModuleImageWrapper
          ...ModuleEmbedMediaWrapper
          ...ProgramCard
          ...StaticList
        }
        mediaContact {
          ...ModuleContact
          ...ModuleContentBody
        }
      }
    }
  }
`;
